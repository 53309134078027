import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import UploadIcon from '@mui/icons-material/Upload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputAdornment, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { ImportBenchmarkNavDataFileDialog } from '../../components/ImportBenchmarkNavDataFileDialog';
import { UploadFileButton } from '../../components/UploadFileButton';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { portfolioFormFields } from '../../forms/fields/formFields';
import { getPortfolioFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAppConfigs } from '../../redux/modules/appConfig/appConfig.actions';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { fetchAssets } from '../../redux/modules/asset/asset.actions';
import { fetchProductTypes } from '../../redux/modules/productType/product-type.actions';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import { assetSelector } from '../../redux/modules/asset/asset.selectors';
import { fetchBenchmarks } from '../../redux/modules/benchmark/benchmark.actions';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { fetchCurrencys } from '../../redux/modules/currency/currency.actions';
import { currencySelector } from '../../redux/modules/currency/currency.selectors';
import { fetchFundManagers } from '../../redux/modules/fundManager/fundManager.actions';
import { fundManagerSelector } from '../../redux/modules/fundManager/fundManager.selectors';
import { fetchIndexFunds, fetchGicsOptions } from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import {
  deletePortfolio,
  fetchPortfolio,
  fetchPortfolioFileTypeOptions,
  fetchPortfolioStatusOptions,
  resetEditPortfolio,
  savePortfolio,
} from '../../redux/modules/portfolio/portfolio.actions';
import { portfolioSelector } from '../../redux/modules/portfolio/portfolio.selectors';
import { fetchUserCategorys } from '../../redux/modules/userCategory/userCategory.actions';
import { userCategorySelector } from '../../redux/modules/userCategory/userCategory.selectors';
import { locations } from '../../routes/locations';
import { ConfigKey } from '../../types/appConfig';
import { UploadEntity } from '../../types/media';
import {
  PortfolioFileType,
  PortfolioStatus,
  UpdatePortfolioRequestAssociatedIndexFund,
  UpdatePortfolioRequestFile,
} from '../../types/portfolio';
import { round2 } from '../../utils/number';
import { getAssetChartItems, getAssetChartItemsByGics } from '../../utils/portfolio';
import AssetDoughnutChart from './AssetDoughnutChart';
import { FakeButtonLink } from '../../components/FakeLink';

const marksPercentage = [
  { value: 0, label: '0%' },
  { value: 20, label: '20%' },
  { value: 50, label: '50%' },
  { value: 80, label: '80%' },
  { value: 100, label: '100%' },
];

export function Portfolio() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [openImportBenchmark, setOpenImportBenchmark] = useState(false);
  const [formSchema, setFormSchema] = useState(getPortfolioFormSchema(0));
  const [chartData, setChartData] = useState([{ label: '', value: 0 }]);
  const {
    fetchStatus,
    editPortfolio,
    deleteStatus,
    saveStatus,
    portfolioStatusOptions,
    portfolioFileTypeOptions,
    fetchPortfolioStatusOptionsStatus,
    fetchPortfolioFileTypeOptionsStatus,
    error: portfolioError,
  } = useSelector(portfolioSelector);
  const { fetchStatus: assetFetchStatus, assets, error: assetError } = useSelector(assetSelector);
  const {
    fetchStatus: indexFundFetchStatus,
    indexFunds,
    fetchStatus: gicsFetchStatus,
    gicsOptions,
    error: indexFundError,
  } = useSelector(indexFundSelector);
  const { fetchStatus: currencyFetchStatus, currencys, error: currencyError } = useSelector(currencySelector);
  const { fetchStatus: benchmarkFetchStatus, benchmarks, error: benchmarkError } = useSelector(benchmarkSelector);
  const { fetchStatus: appConfigFetchStatus, appConfigs, error: appConfigError } = useSelector(appConfigSelector);
  const {
    fetchStatus: productTypeFetchStatus,
    productTypes,
    error: productTypeError,
  } = useSelector(productTypeSelector);
  const {
    fetchStatus: userCategoryFetchStatus,
    userCategorys,
    error: userCategoryError,
  } = useSelector(userCategorySelector);
  const {
    fetchStatus: fundManagerFetchStatus,
    fundManagers,
    error: fundManagerError,
  } = useSelector(fundManagerSelector);
  const { enqueueSnackbar } = useSnackbar();
  const isLoading =
    fetchStatus === fetchStatuses.pending ||
    assetFetchStatus === fetchStatuses.pending ||
    gicsFetchStatus === fetchStatuses.pending ||
    currencyFetchStatus === fetchStatuses.pending ||
    fundManagerFetchStatus === fetchStatuses.pending ||
    indexFundFetchStatus === fetchStatuses.pending ||
    benchmarkFetchStatus === fetchStatuses.pending ||
    appConfigFetchStatus === fetchStatuses.pending ||
    userCategoryFetchStatus === fetchStatuses.pending ||
    fetchPortfolioStatusOptionsStatus === fetchStatuses.pending ||
    fetchPortfolioFileTypeOptionsStatus === fetchStatuses.pending ||
    productTypeFetchStatus === fetchStatuses.pending;
  const isDeleteLoading = isLoading || deleteStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || saveStatus === fetchStatuses.pending;
  const error =
    portfolioError ||
    currencyError ||
    fundManagerError ||
    userCategoryError ||
    assetError ||
    indexFundError ||
    benchmarkError ||
    appConfigError ||
    productTypeError;
  const formik = useFormik({
    initialValues: {
      [portfolioFormFields.nameEn]: '',
      [portfolioFormFields.nameAr]: '',
      [portfolioFormFields.descriptionEn]: '',
      [portfolioFormFields.descriptionAr]: '',
      [portfolioFormFields.objectiveEn]: '',
      [portfolioFormFields.objectiveAr]: '',
      [portfolioFormFields.shariaaComplaint]: true,
      [portfolioFormFields.userCategory]: [] as number[],
      [portfolioFormFields.status]: 0,
      [portfolioFormFields.currency]: 0,
      [portfolioFormFields.manager]: 0,
      [portfolioFormFields.productType]: 0,
      [portfolioFormFields.expectedMinimumReturn]: 0,
      [portfolioFormFields.targetExpectedReturn]: 0,
      [portfolioFormFields.expectedMaximumReturn]: 0,
      [portfolioFormFields.targetExpectedRisk]: 0,
      [portfolioFormFields.benchmark]: 0,
      [portfolioFormFields.displayByGics]: false,
      [portfolioFormFields.sharpRatio]: 0,
      [portfolioFormFields.files]: [] as UpdatePortfolioRequestFile[],
      [portfolioFormFields.associatedIndexFunds]: [] as UpdatePortfolioRequestAssociatedIndexFund[],
    },
    validationSchema: formSchema,
    onSubmit: (formValues) => {
      // return alert(JSON.stringify(formValues, null, 4));

      formValues.associatedIndexFunds = formValues.associatedIndexFunds.map((aif) => ({ ...aif, weight: +aif.weight }));
      formValues.userCategory = Array.from(new Set([...formValues.userCategory]));
      formValues.expectedMinimumReturn = +formValues.expectedMinimumReturn;
      formValues.targetExpectedReturn = +formValues.targetExpectedReturn;
      formValues.expectedMaximumReturn = +formValues.expectedMaximumReturn;
      formValues.targetExpectedRisk = +formValues.targetExpectedRisk;
      formValues.sharpRatio = +formValues.sharpRatio;
      formValues.productType = +formValues.productType;

      if (Number(id)) {
        dispatch(savePortfolio({ id: Number(id), ...formValues }));
      } else {
        dispatch(savePortfolio(formValues));
      }
    },
  });
  const { handleSubmit, values, handleChange, touched, errors, setValues, setFieldValue } = formik;
  const selectedBenchmark = benchmarks.find((i) => i.id === values[portfolioFormFields.benchmark]);
  const cashAllocationPercentage = Number(
    appConfigs.find((i) => i.key === ConfigKey.CACHE_ALLOCATION_PERCENTAGE)?.value || 0,
  );

  useEffect(() => {
    const chartItems = values[portfolioFormFields.displayByGics]
      ? getAssetChartItemsByGics(indexFunds, values[portfolioFormFields.associatedIndexFunds], cashAllocationPercentage)
      : getAssetChartItems(indexFunds, values[portfolioFormFields.associatedIndexFunds], cashAllocationPercentage);
    setChartData(chartItems);
  }, [values[portfolioFormFields.displayByGics]]);

  useEffect(() => {
    setFormSchema(getPortfolioFormSchema(cashAllocationPercentage));
  }, [cashAllocationPercentage]);

  useEffect(() => {
    dispatch(fetchFundManagers({ limit: 100, offset: 0 }));
    dispatch(fetchCurrencys({ limit: 100, offset: 0 }));
    dispatch(fetchUserCategorys({ limit: 100, offset: 0 }));
    dispatch(fetchAssets({ limit: 100, offset: 0 }));
    dispatch(fetchGicsOptions());
    dispatch(fetchIndexFunds({ limit: 100, offset: 0 }));
    dispatch(fetchBenchmarks({ limit: 100, offset: 0 }));
    dispatch(fetchAppConfigs());
    dispatch(fetchPortfolioStatusOptions());
    dispatch(fetchPortfolioFileTypeOptions());
    dispatch(fetchProductTypes({ limit: 100, offset: 0 }));

    return () => {
      dispatch(resetEditPortfolio());
    };
  }, []);

  const getDefaultFiles = () => {
    const factSheetFileType = portfolioFileTypeOptions.find((pft) => pft.name === PortfolioFileType.FACT_SHEET);
    const factSheetArFileType = portfolioFileTypeOptions.find((pft) => pft.name === PortfolioFileType.FACT_SHEET_AR);
    const disclosureFileType = portfolioFileTypeOptions.find((pft) => pft.name === PortfolioFileType.DISCLOSURE);

    return [
      { portfolioFileType: factSheetFileType?.id || 0, fileId: '', isPublic: true },
      { portfolioFileType: factSheetArFileType?.id || 0, fileId: '', isPublic: true },
      { portfolioFileType: disclosureFileType?.id || 0, fileId: '', isPublic: true },
    ];
  };

  useEffect(() => {
    if (fetchPortfolioStatusOptionsStatus === fetchStatuses.success && !values[portfolioFormFields.status]) {
      const activeStatus = portfolioStatusOptions.find((item) => item.name === PortfolioStatus.ACTIVE);

      setFieldValue(portfolioFormFields.status, activeStatus?.id || 0);
    }
    if (fetchPortfolioFileTypeOptionsStatus === fetchStatuses.success && !values[portfolioFormFields.files].length) {
      setFieldValue(portfolioFormFields.files, getDefaultFiles());
    }
  }, [portfolioStatusOptions, fetchPortfolioStatusOptionsStatus, fetchPortfolioFileTypeOptionsStatus]);

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      enqueueSnackbar('Portfolio deleted!', { variant: 'success' });
      navigate(locations.portfolioList());
    }
    if (saveStatus === fetchStatuses.success) {
      if (Number(id)) {
        dispatch(fetchPortfolio(Number(id)));
      }
      enqueueSnackbar('Portfolio saved!', { variant: 'success' });
    }
  }, [deleteStatus, saveStatus]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(fetchPortfolio(Number(id)));
    }
  }, [id]);

  useEffect(() => {
    if (editPortfolio?.id && editPortfolio?.id !== Number(id)) {
      navigate(locations.portfolio(editPortfolio?.id));
      return;
    }

    if (editPortfolio) {
      const existingFiles = editPortfolio.files?.map((f) => ({
        id: f.id,
        fileId: f.fileId,
        portfolioFileType: f.portfolioFileType?.id,
        isPublic: f.isPublic,
      }));

      getDefaultFiles().forEach((df) => {
        if (!existingFiles.find((f) => f.portfolioFileType === df.portfolioFileType)) {
          existingFiles.unshift({ ...df, id: 0 });
        }
      });

      const associatedIndexFunds =
        editPortfolio.associatedIndexFunds?.map((aif) => ({
          id: aif.id,
          indexFund: aif?.indexFund?.id,
          weight: aif.weight,
        })) || [];

      setValues({
        [portfolioFormFields.nameEn]: editPortfolio.nameEn,
        [portfolioFormFields.nameAr]: editPortfolio.nameAr,
        [portfolioFormFields.descriptionEn]: editPortfolio.descriptionEn,
        [portfolioFormFields.descriptionAr]: editPortfolio.descriptionAr,
        [portfolioFormFields.objectiveEn]: editPortfolio.objectiveEn,
        [portfolioFormFields.objectiveAr]: editPortfolio.objectiveAr,
        [portfolioFormFields.shariaaComplaint]: editPortfolio.shariaaComplaint,
        [portfolioFormFields.userCategory]: editPortfolio.userCategory?.map((uc) => uc?.userCategory?.id) || [],
        [portfolioFormFields.status]: editPortfolio.status?.id,
        [portfolioFormFields.currency]: editPortfolio.currency?.id,
        [portfolioFormFields.manager]: editPortfolio.manager?.id,
        [portfolioFormFields.expectedMinimumReturn]: editPortfolio.expectedMinimumReturn || 0,
        [portfolioFormFields.targetExpectedReturn]: editPortfolio.targetExpectedReturn || 0,
        [portfolioFormFields.expectedMaximumReturn]: editPortfolio.expectedMaximumReturn || 0,
        [portfolioFormFields.targetExpectedRisk]: editPortfolio.targetExpectedRisk || 0,
        [portfolioFormFields.benchmark]: editPortfolio.benchmark?.id || 0,
        [portfolioFormFields.sharpRatio]: +editPortfolio.sharpRatio || 0,
        [portfolioFormFields.files]: existingFiles,
        [portfolioFormFields.productType]: editPortfolio.productType?.id,
        [portfolioFormFields.displayByGics]: editPortfolio.displayByGics || false,
        [portfolioFormFields.associatedIndexFunds]: associatedIndexFunds,
      });

      setChartData(getAssetChartItems(indexFunds, associatedIndexFunds, cashAllocationPercentage));
    }
  }, [editPortfolio]);

  const onDeletePortfolio = () => {
    dispatch(deletePortfolio(Number(id)));
  };

  const getFilesKey = (index: number) => `file-${index}`;
  const getAssetKey = (index: number) => `asset-${index}`;
  const getAssociatedIndexFundsKey = (index: number) => `associated-index-funds-${index}`;
  const valueText = (value: number) => `${value}%`;

  function getSelectedIndexFund(indexFundId: number) {
    return indexFunds.find((i) => i.id === indexFundId);
  }

  function getSelectedPortfolioFileType(fileTypeId: number) {
    return portfolioFileTypeOptions.find((i) => i.id === fileTypeId);
  }

  function getPortfolioFileTypeByName(name: PortfolioFileType) {
    return portfolioFileTypeOptions.find((i) => i.name === name);
  }

  // TODO: move to separate component
  const renderAssetsData = () =>
    values[portfolioFormFields.associatedIndexFunds].map((aif, index) => (
      <Grid container item spacing={3} key={getAssetKey(index)}>
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="asset-label" required>
                Asset
              </InputLabel>
              <Select
                labelId="asset-label"
                id={`associatedIndexFunds.${index}.asset`}
                name={`associatedIndexFunds.${index}.asset`}
                value={getSelectedIndexFund(aif.indexFund)?.asset?.id || 0}
                label="Asset Class"
                disabled
              >
                {assets.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControlLabel
              sx={{ width: '100%', marginX: 0 }}
              label="Weight"
              labelPlacement="top"
              control={
                <FormControl fullWidth margin="none" disabled>
                  <Slider
                    disabled
                    aria-label="Weight"
                    defaultValue={1}
                    id={`associatedIndexFunds.${index}.weight`}
                    name={`associatedIndexFunds.${index}.weight`}
                    value={aif.weight || 0}
                    valueLabelFormat={valueText}
                    getAriaValueText={valueText}
                    valueLabelDisplay="on"
                    marks={marksPercentage}
                  />
                </FormControl>
              }
            />
          )}
        </Grid>
      </Grid>
    ));

  // TODO: move to separate component
  const renderGicsData = () => {
    const gicsByWeight = values[portfolioFormFields.associatedIndexFunds].reduce(
      (res, aif) => {
        const indexFund = getSelectedIndexFund(aif.indexFund);

        if (indexFund) {
          for (const gics of indexFund.gics as any) {
            res[gics.gics.id!] = (res[gics.gics.id!] || 0) + (gics.weight * aif.weight) / 100;
          }
        }

        return res;
      },
      {} as Record<number, number>,
    );

    return Object.keys(gicsByWeight).map((key, index) => (
      <Grid container item spacing={3} key={getAssetKey(index)}>
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="gics-label" required>
                Gics
              </InputLabel>
              <Select
                labelId="gics-label"
                id={`gics.${index}.gics`}
                name={`gics.${index}.gics`}
                value={key}
                label="Gics"
                disabled
              >
                {gicsOptions.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.nameEn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControlLabel
              sx={{ width: '100%', marginX: 0 }}
              label="Weight"
              labelPlacement="top"
              control={
                <FormControl fullWidth margin="none" disabled>
                  <Slider
                    disabled
                    aria-label="Weight"
                    defaultValue={1}
                    id={`gics.${index}.weight`}
                    name={`gics.${index}.weight`}
                    value={round2(gicsByWeight[key] || 0)}
                    valueLabelFormat={valueText}
                    getAriaValueText={valueText}
                    valueLabelDisplay="on"
                    marks={marksPercentage}
                  />
                </FormControl>
              }
            />
          )}
        </Grid>
      </Grid>
    ));
  };

  const renderFileDeleteButton = (item: UpdatePortfolioRequestFile, index, arrayHelpers) => {
    const selectedFileType = getSelectedPortfolioFileType(item.portfolioFileType);
    const isRequiredFile =
      selectedFileType &&
      [PortfolioFileType.DISCLOSURE, PortfolioFileType.FACT_SHEET, PortfolioFileType.FACT_SHEET_AR].includes(
        selectedFileType.name,
      );

    if (item.fileId || !isRequiredFile) {
      return (
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            if (!isRequiredFile) {
              arrayHelpers.remove(index);
            } else {
              setFieldValue(`files.${index}.fileId`, '');
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Grid container flex={1} flexDirection="row" justifyContent="flex-start" alignItems="center">
              <Typography color="text.primary" component="h4" gutterBottom>
                Portfolio
              </Typography>
              {editPortfolio?.productType && (
                <Tooltip title={`(${editPortfolio.productType.id}) ${editPortfolio.productType.nameEn}`} followCursor>
                  <FakeButtonLink
                    width="200px"
                    startIcon={<OpenInNewIcon />}
                    href={locations.portfolio(editPortfolio.productType.id)}
                  >
                    {`(${editPortfolio.productType.id}) ${editPortfolio.productType.nameEn}`}
                  </FakeButtonLink>
                </Tooltip>
              )}
            </Grid>
            <Grid container spacing={2} paddingX={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nameEn"
                    name={portfolioFormFields.nameEn}
                    label="Name En"
                    type="nameEn"
                    value={values[portfolioFormFields.nameEn]}
                    onChange={handleChange}
                    error={touched.nameEn && !!errors.nameEn}
                    helperText={touched.nameEn && errors.nameEn}
                    autoComplete="nameEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="nameAr"
                    fullWidth
                    name={portfolioFormFields.nameAr}
                    label="Name Ar"
                    value={values[portfolioFormFields.nameAr]}
                    onChange={handleChange}
                    error={touched.nameAr && !!errors.nameAr}
                    helperText={touched.nameAr && errors.nameAr}
                    autoComplete="nameAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="descriptionEn"
                    name={portfolioFormFields.descriptionEn}
                    label="Description En"
                    type="descriptionEn"
                    value={values[portfolioFormFields.descriptionEn]}
                    onChange={handleChange}
                    error={touched.descriptionEn && !!errors.descriptionEn}
                    helperText={touched.descriptionEn && errors.descriptionEn}
                    autoComplete="descriptionEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="descriptionAr"
                    fullWidth
                    name={portfolioFormFields.descriptionAr}
                    label="Description Ar"
                    value={values[portfolioFormFields.descriptionAr]}
                    onChange={handleChange}
                    error={touched.descriptionAr && !!errors.descriptionAr}
                    helperText={touched.descriptionAr && errors.descriptionAr}
                    autoComplete="descriptionAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.userCategory && !!errors.userCategory}>
                    <InputLabel id="user-category-label" required>
                      User Category
                    </InputLabel>
                    <Select
                      labelId="user-category-label"
                      name={portfolioFormFields.userCategory}
                      value={values[portfolioFormFields.userCategory] as number[]}
                      label="User Category"
                      onChange={handleChange}
                      required
                      multiple
                    >
                      {userCategorys.map((uc) => (
                        <MenuItem key={uc.id} value={uc.id}>
                          {uc.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.userCategory && <FormHelperText>{errors.userCategory}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status-label" required>
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      name={portfolioFormFields.status}
                      value={values[portfolioFormFields.status] || ''}
                      label="Status"
                      onChange={handleChange}
                    >
                      {portfolioStatusOptions.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.status && <FormHelperText>{errors.status}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.currency && !!errors.currency}>
                    <InputLabel id="currency-label" required>
                      Currency
                    </InputLabel>
                    <Select
                      labelId="currency-label"
                      name={portfolioFormFields.currency}
                      value={values[portfolioFormFields.currency] || ''}
                      label="Currency"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {currencys.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.currency && <FormHelperText>{errors.currency}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.manager && !!errors.manager}>
                    <InputLabel id="manager-label" required>
                      Manager
                    </InputLabel>
                    <Select
                      labelId="manager-label"
                      name={portfolioFormFields.manager}
                      value={values[portfolioFormFields.manager] || ''}
                      label="Manager"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {fundManagers.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.manager && <FormHelperText>{errors.manager}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width={192} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Shariaa Complaint"
                    labelPlacement="start"
                    componentsProps={{
                      typography: { textTransform: 'capitalize' },
                    }}
                    name={portfolioFormFields.shariaaComplaint}
                    id={portfolioFormFields.shariaaComplaint}
                    control={
                      <Switch
                        name={portfolioFormFields.shariaaComplaint}
                        checked={values[portfolioFormFields.shariaaComplaint]}
                        onChange={handleChange}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="objectiveEn"
                    name={portfolioFormFields.objectiveEn}
                    label="Objective En"
                    type="objectiveEn"
                    value={values[portfolioFormFields.objectiveEn]}
                    onChange={handleChange}
                    error={touched.objectiveEn && !!errors.objectiveEn}
                    helperText={touched.objectiveEn && errors.objectiveEn}
                    autoComplete="objectiveEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="objectiveAr"
                    fullWidth
                    name={portfolioFormFields.objectiveAr}
                    label="Objective Ar"
                    value={values[portfolioFormFields.objectiveAr]}
                    onChange={handleChange}
                    error={touched.objectiveAr && !!errors.objectiveAr}
                    helperText={touched.objectiveAr && errors.objectiveAr}
                    autoComplete="objectiveAr"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.benchmark && !!errors.benchmark}>
                    <InputLabel id="benchmark-label" required>
                      Benchmark
                    </InputLabel>
                    <Select
                      labelId="benchmark-label"
                      name={portfolioFormFields.benchmark}
                      value={values[portfolioFormFields.benchmark] || ''}
                      label="Benchmark"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {benchmarks.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameAr}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.benchmark && <FormHelperText>{errors.benchmark}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.productType && !!errors.productType}>
                    <InputLabel id="product-type-label" required>
                      Product Type
                    </InputLabel>
                    <Select
                      labelId="product-type-label"
                      name={portfolioFormFields.productType}
                      value={values[portfolioFormFields.productType] || ''}
                      label="Product Type"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {productTypes.map((pt) => (
                        <MenuItem key={pt.id} value={pt.id}>
                          {pt.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.productType && <FormHelperText>{errors.productType}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Files
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="files"
                render={(arrayHelpers) => (
                  <>
                    <List>
                      {values[portfolioFormFields.files].map((item, index) => (
                        <ListItem
                          key={getFilesKey(index)}
                          secondaryAction={renderFileDeleteButton(item, index, arrayHelpers)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <UploadFileButton
                                accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
                                uploadEntity={UploadEntity.orderFile}
                                selectedFile={item.fileId}
                                isPrivate
                                multiple={
                                  getSelectedPortfolioFileType(item.portfolioFileType)?.name === PortfolioFileType.OTHER
                                }
                                onChange={(uploadedFiles) => {
                                  if (uploadedFiles.length) {
                                    for (let i = 0; i < uploadedFiles.length; i++) {
                                      if (index + i > values[portfolioFormFields.files].length - 1) {
                                        arrayHelpers.push({
                                          isPublic: false,
                                          fileId: '',
                                          portfolioFileType: getPortfolioFileTypeByName(PortfolioFileType.OTHER)?.id,
                                        });
                                      }
                                      setFieldValue(`files.${index + i}.fileId`, uploadedFiles[i]);
                                    }
                                  }
                                }}
                                onError={(uploadError) => {
                                  enqueueSnackbar(uploadError, { variant: 'error' });
                                }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={getSelectedPortfolioFileType(item.portfolioFileType)?.name}
                            secondary={
                              item.fileId || (errors.files?.[index] as FormikErrors<UpdatePortfolioRequestFile>)?.fileId
                            }
                            primaryTypographyProps={{ fontWeight: 600 }}
                            secondaryTypographyProps={{ color: item.fileId ? 'unset' : 'red' }}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                edge="end"
                                id={`files.${index}.isPublic`}
                                name={`files.${index}.isPublic`}
                                onChange={handleChange}
                                checked={item.isPublic}
                                inputProps={{
                                  'aria-labelledby': 'switch-file-public',
                                }}
                              />
                            }
                            label="Display to user"
                            labelPlacement="start"
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          arrayHelpers.push({
                            isPublic: false,
                            fileId: '',
                            portfolioFileType: getPortfolioFileTypeByName(PortfolioFileType.OTHER)?.id,
                          })
                        }
                      >
                        Add
                      </Button>
                    </Box>
                  </>
                )}
              />
              {!!errors.files && typeof errors.files === 'string' && (
                <FormHelperText error>{errors.files}</FormHelperText>
              )}
            </FormikProvider>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Options
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={2} paddingLeft={2} paddingRight={4}>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={portfolioFormFields.expectedMinimumReturn}
                    name={portfolioFormFields.expectedMinimumReturn}
                    label="Expected Minimum Return %"
                    value={values[portfolioFormFields.expectedMinimumReturn]}
                    onChange={handleChange}
                    error={touched.expectedMinimumReturn && !!errors.expectedMinimumReturn}
                    helperText={touched.expectedMinimumReturn && errors.expectedMinimumReturn}
                    autoComplete="portfolio-expectedMinimumReturn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={portfolioFormFields.targetExpectedReturn}
                    name={portfolioFormFields.targetExpectedReturn}
                    label="Target Expected Return %"
                    value={values[portfolioFormFields.targetExpectedReturn]}
                    onChange={handleChange}
                    error={touched.targetExpectedReturn && !!errors.targetExpectedReturn}
                    helperText={touched.targetExpectedReturn && errors.targetExpectedReturn}
                    autoComplete="portfolio-targetExpectedReturn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={portfolioFormFields.expectedMaximumReturn}
                    name={portfolioFormFields.expectedMaximumReturn}
                    label="Expected Maximum Return %"
                    value={values[portfolioFormFields.expectedMaximumReturn]}
                    onChange={handleChange}
                    error={touched.expectedMaximumReturn && !!errors.expectedMaximumReturn}
                    helperText={touched.expectedMaximumReturn && errors.expectedMaximumReturn}
                    autoComplete="portfolio-expectedMaximumReturn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={portfolioFormFields.targetExpectedRisk}
                    name={portfolioFormFields.targetExpectedRisk}
                    label="Target Expected Risk %"
                    value={values[portfolioFormFields.targetExpectedRisk]}
                    onChange={handleChange}
                    error={touched.targetExpectedRisk && !!errors.targetExpectedRisk}
                    helperText={touched.targetExpectedRisk && errors.targetExpectedRisk}
                    autoComplete="portfolio-targetExpectedRisk"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 1 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={portfolioFormFields.sharpRatio}
                    name={portfolioFormFields.sharpRatio}
                    label="Sharp Ratio %"
                    value={values[portfolioFormFields.sharpRatio]}
                    onChange={handleChange}
                    error={touched.sharpRatio && !!errors.sharpRatio}
                    helperText={touched.sharpRatio && errors.sharpRatio}
                    autoComplete="portfolio-sharpRatio"
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" sx={{ mb: 2 }} component="h4" gutterBottom>
              {values[portfolioFormFields.displayByGics] ? 'Gics' : 'Assets'}
            </Typography>
            <Grid container rowSpacing={2} paddingLeft={2} paddingRight={4}>
              <Grid item container columnSpacing={4}>
                <Grid item xs={12} md={12} lg={7}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="cashAllocationPercentage"
                      name="cashAllocationPercentage"
                      label="Cash Allocation Percentage"
                      value={cashAllocationPercentage}
                      disabled
                    />
                  )}
                  {values[portfolioFormFields.displayByGics] ? renderGicsData() : renderAssetsData()}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={5}
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '40px',
                      position: 'absolute',
                      top: '60%',
                      left: 0,
                      marginTop: '-20px',
                      lineHeight: '19px',
                      textAlign: 'center',
                      zIndex: 2,
                    }}
                  >
                    {round2(
                      values[portfolioFormFields.associatedIndexFunds].reduce(
                        (res, item) => res + +item.weight,
                        cashAllocationPercentage,
                      ),
                    )}
                    %
                  </Box>
                  <AssetDoughnutChart items={chartData} />
                </Grid>
              </Grid>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>By Assets</Grid>
                <Grid item>
                  <Switch
                    name={portfolioFormFields.displayByGics}
                    checked={values[portfolioFormFields.displayByGics]}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>By GICS</Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" sx={{ mb: 2 }} component="h4" gutterBottom>
              Associated Funds/Index
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="associatedIndexFunds"
                render={(arrayHelpers) => (
                  <Grid container spacing={2} paddingX={2}>
                    {values[portfolioFormFields.associatedIndexFunds] &&
                    values[portfolioFormFields.associatedIndexFunds].length > 0 ? (
                      values[portfolioFormFields.associatedIndexFunds].map((item, index) => (
                        <Grid container item spacing={3} key={getAssociatedIndexFundsKey(index)}>
                          <Grid item xs={12} md={6} lg={4}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Box display="flex">
                                <FakeButtonLink
                                  startIcon={<OpenInNewIcon />}
                                  href={locations.indexFund(item.indexFund)}
                                >
                                  {item.indexFund}
                                </FakeButtonLink>
                                <FormControl
                                  sx={{ minWidth: '85%' }}
                                  margin="normal"
                                  error={
                                    touched.associatedIndexFunds?.[index]?.indexFund &&
                                    !!(
                                      errors.associatedIndexFunds?.[
                                        index
                                      ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                    )?.indexFund
                                  }
                                >
                                  <InputLabel id="fund-label" required>
                                    Fund
                                  </InputLabel>
                                  <Select
                                    labelId="fund-label"
                                    id={`associatedIndexFunds.${index}.indexFund`}
                                    name={`associatedIndexFunds.${index}.indexFund`}
                                    value={item.indexFund}
                                    label="Fund"
                                    onChange={handleChange}
                                  >
                                    {indexFunds.map((a) => (
                                      <MenuItem key={a.id} value={a.id}>
                                        {a.nameEn}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {touched.associatedIndexFunds?.[index]?.indexFund && (
                                    <FormHelperText>
                                      {
                                        (
                                          errors.associatedIndexFunds?.[
                                            index
                                          ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                        )?.indexFund
                                      }
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FormControl
                                fullWidth
                                margin="normal"
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              >
                                <InputLabel id="asset-class-label" required>
                                  Asset Class
                                </InputLabel>
                                <Select
                                  labelId="asset-class-label"
                                  id={`associatedIndexFunds.${index}.asset`}
                                  name={`associatedIndexFunds.${index}.asset`}
                                  value={getSelectedIndexFund(item.indexFund)?.asset?.id || 0}
                                  label="Asset Class"
                                  disabled
                                >
                                  {assets.map((a) => (
                                    <MenuItem key={a.id} value={a.id}>
                                      {a.nameEn}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.associatedIndexFunds?.[index]?.indexFund && (
                                  <FormHelperText>
                                    {
                                      (
                                        errors.associatedIndexFunds?.[
                                          index
                                        ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                      )?.indexFund
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id={`associatedIndexFunds.${index}.region`}
                                name={`associatedIndexFunds.${index}.region`}
                                label="Region"
                                type="text"
                                value={getSelectedIndexFund(item.indexFund)?.geographicalFocus?.nameEn || ''}
                                disabled
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                                helperText={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  (
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                id={`associatedIndexFunds.${index}.strategy`}
                                name={`associatedIndexFunds.${index}.strategy`}
                                label="Strategy Obj"
                                type="text"
                                value={getSelectedIndexFund(item.indexFund)?.assetClass?.nameEn || ''}
                                disabled
                                error={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                                helperText={
                                  touched.associatedIndexFunds?.[index]?.indexFund &&
                                  (
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.indexFund
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={10} md={6} lg={1} display="flex" alignItems="center">
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <DecimalNumberField
                                margin="normal"
                                fullWidth
                                id={`associatedIndexFunds.${index}.weight`}
                                name={`associatedIndexFunds.${index}.weight`}
                                label="Weight %"
                                value={item.weight || 0}
                                onChange={handleChange}
                                autoComplete="Weight"
                                error={
                                  touched.associatedIndexFunds?.[index]?.weight &&
                                  !!(
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.weight
                                }
                                helperText={
                                  touched.associatedIndexFunds?.[index]?.weight &&
                                  (
                                    errors.associatedIndexFunds?.[
                                      index
                                    ] as FormikErrors<UpdatePortfolioRequestAssociatedIndexFund>
                                  )?.weight
                                }
                              />
                            )}
                          </Grid>
                          <Grid container item xs={2} spacing={2} display="flex" alignItems="center">
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Remove index fund"
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Add index fund"
                                size="small"
                                onClick={() => arrayHelpers.insert(index + 1, { indexFund: 0, weight: 0 })}
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Box>
                        <Button variant="outlined" onClick={() => arrayHelpers.push({ indexFund: 0, weight: 0 })}>
                          Add an Index Fund
                        </Button>
                      </Box>
                    )}
                  </Grid>
                )}
              />
              {!!errors.associatedIndexFunds && typeof errors.associatedIndexFunds === 'string' && (
                <FormHelperText error>{errors.associatedIndexFunds}</FormHelperText>
              )}
            </FormikProvider>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
          {editPortfolio ? (
            <LoadingButton
              type="button"
              color="error"
              variant="contained"
              sx={{ mt: 3, mb: 2, mr: 2 }}
              endIcon={<DeleteIcon />}
              loading={isDeleteLoading}
              loadingPosition="end"
              onClick={onDeletePortfolio}
            >
              Delete
            </LoadingButton>
          ) : (
            <div />
          )}
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
          >
            {editPortfolio ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Grid>
    </form>
  );

  const importBenchmarkNavDataFile = (): void => {
    setOpenImportBenchmark(true);
  };

  const handleClose = () => {
    setOpenImportBenchmark(false);
  };

  return (
    <Container component="main" maxWidth="xl">
      {error && (
        <Alert severity="error">
          <pre>{error.message}</pre>
        </Alert>
      )}

      <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Grid item>&nbsp;</Grid>
        <Grid item>
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            disabled={!selectedBenchmark}
            onClick={importBenchmarkNavDataFile}
          >
            Import Benchmark Nav Data File
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>{renderForm()}</Box>

      {selectedBenchmark && (
        <ImportBenchmarkNavDataFileDialog
          open={openImportBenchmark}
          onClose={handleClose}
          benchmark={selectedBenchmark}
        />
      )}
    </Container>
  );
}
